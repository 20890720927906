/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "@material-ui/core";
import { useRef, useMemo, useEffect, useState, ReactNode } from "react";

import { Button, ButtonProps } from "@rewards-web/shared/components/button";
import { Typography } from "@rewards-web/shared/components/typography";
import { useOnScreen } from "@rewards-web/shared/hooks/use-on-screen";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import completeUrl from "../../graphics/complete.png";
import { lockImage } from "../../graphics/lock";
import { GoalCardPill, GoalPillProps } from "./goal-card-pill";
import {
  GoalCardProgressBar,
  GoalCardProgressBarProps,
} from "./goal-card-progress-bar";
import { GoalDetailsModal } from "./goal-details-modal";
import { GoalReward, GoalRewardProps } from "./goal-reward";

const TINY_WIDTH = 400;

export interface BaseGoalCardProps {
  icon: JSX.Element;
  analyticsData: { [key: string]: any };
  title: string;
  detailText?: string;
  status: "achieved" | "locked" | "available";
  rewardConfig: GoalRewardProps["rewardConfig"];
  detailButtonConfig?:
    | { type: "link"; path: string }
    | {
        type: "modal";
        contentsText: ReactNode;
      };
  progressProps?: GoalCardProgressBarProps;
  topRightPillProps?: Omit<GoalPillProps, "locked">;
  pointsPillProps?: Omit<GoalPillProps, "locked">;
  primaryButtonProps?: Pick<
    ButtonProps,
    "label" | "linkTo" | "onClick" | "externalLink"
  >;
}

export function BaseGoalCard({
  icon,
  analyticsData,
  title,
  detailText,
  rewardConfig,
  progressProps,
  detailButtonConfig,
  primaryButtonProps,
  topRightPillProps,
  status,
  pointsPillProps,
}: BaseGoalCardProps) {
  const { formatMessage } = useFormatters();

  const ref = useRef<HTMLDivElement>(null);
  const track = useTrack();
  const onScreen = useOnScreen(ref, { threshold: 0.5 });

  const tinyScreen = useMediaQuery(`(max-width:${TINY_WIDTH - 1}px)`);
  const smallScreen = useMediaQuery((theme: AppTheme) =>
    theme.breakpoints.down("xs")
  );

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  useEffect(() => {
    if (detailsModalOpen && detailButtonConfig?.type !== "modal") {
      // clean up if `detailButtonConfig.type` changes,
      // to ensure modal doesn't open back up if it changes back
      setDetailsModalOpen(false);
    }
  }, [detailsModalOpen, detailButtonConfig?.type]);

  const buttonWidth = tinyScreen ? 115 : smallScreen ? 130 : 160;

  const trackingProperties = useMemo(
    () => ({
      goalCardTitle: title,
      goalCardButtonLabel: primaryButtonProps?.label,
      ...analyticsData,
    }),
    [analyticsData, primaryButtonProps?.label, title]
  );

  useEffect(() => {
    if (onScreen) {
      track("Viewed goal card", trackingProperties);
    }
  }, [track, onScreen, trackingProperties]);

  const isGoalLocked = status === "locked";

  const goalIcon = useMemo(() => {
    switch (status) {
      case "available":
        return icon;
      case "locked":
        return lockImage;
      case "achieved":
        return (
          <img
            src={completeUrl}
            alt="check mark"
            css={css`
              scale: 0.9;
            `}
          />
        );
      default:
        return null;
    }
  }, [icon, status]);

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
      `}
    >
      <div
        css={(appTheme: AppTheme) => css`
          position: absolute;
          left: 0px;
          top: ${appTheme.spacing(1.75)};
          > * {
            width: ${appTheme.spacing(5.5)};
            height: ${appTheme.spacing(5.5)};

            @media (min-width: ${TINY_WIDTH}px) {
              width: ${appTheme.spacing(8)};
              height: ${appTheme.spacing(8)};
            }
          }
        `}
      >
        {goalIcon}
      </div>
      <div
        css={(appTheme: AppTheme) =>
          css`
            border: 1.5px solid ${appTheme.palette.divider};
            padding: ${appTheme.spacing(2.5)};
            border-radius: 10px;
            background-color: ${isGoalLocked
              ? appTheme.palette.grey[200]
              : "white"};

            margin-left: ${appTheme.spacing(2)};
            @media (min-width: ${TINY_WIDTH}px) {
              margin-left: ${appTheme.spacing(3)};
            }
          `
        }
      >
        <div
          css={(appTheme: AppTheme) => css`
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            margin-left: ${appTheme.spacing(2)};
            @media (min-width: ${TINY_WIDTH}px) {
              margin-left: ${appTheme.spacing(4)};
            }
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <div
              css={(appTheme: AppTheme) => css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                gap: ${appTheme.spacing(1)};
                flex-wrap: wrap;
                margin-bottom: ${appTheme.spacing(1)};
              `}
            >
              <Typography
                variant="h6"
                css={css`
                  flex-basis: min-content;
                  flex-grow: 1;
                `}
              >
                {title}
              </Typography>
              {topRightPillProps && (
                <div
                  css={(appTheme: AppTheme) =>
                    css`
                      margin-top: ${appTheme.spacing(0.375)};
                      margin-bottom: ${appTheme.spacing(0.375)};
                    `
                  }
                >
                  <GoalCardPill
                    {...topRightPillProps}
                    locked={status === "locked"}
                  />
                </div>
              )}
            </div>
            {detailText && (
              <Typography
                variant="footnote"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                {detailText}
              </Typography>
            )}
            <div
              css={(appTheme: AppTheme) => css`
                display: flex;
                column-gap: ${appTheme.spacing(1)};
                row-gap: ${appTheme.spacing(1)};
                align-items: center;
                flex-wrap: wrap;
              `}
            >
              <GoalReward
                status={status}
                rewardConfig={rewardConfig}
                opacity={isGoalLocked ? "50%" : undefined}
              />
              {progressProps && status === "available" && (
                <GoalCardProgressBar {...progressProps} />
              )}
              {pointsPillProps && (
                <GoalCardPill
                  {...pointsPillProps}
                  locked={status === "locked"}
                />
              )}
            </div>
            {(primaryButtonProps || detailButtonConfig) && (
              <div
                css={(appTheme: AppTheme) =>
                  css`
                    margin-top: ${appTheme.spacing(2)};
                    margin-left: auto;

                    ${primaryButtonProps && detailButtonConfig
                      ? css`
                          display: grid;
                          // each card is fixed width,
                          // then it collapses to one column when it can't fit
                          grid-template-columns: repeat(
                            auto-fit,
                            minmax(${buttonWidth}px, 1fr)
                          );
                          grid-gap: ${appTheme.spacing(1)};

                          // right-align when cards are in 2 columns
                          width: min(
                            100%,
                            calc(2 * ${buttonWidth}px + ${appTheme.spacing(1)})
                          );
                        `
                      : css`
                          width: 100%;
                          max-width: ${buttonWidth}px;
                        `}
                  `
                }
              >
                {detailButtonConfig && (
                  <>
                    <Button
                      width="full"
                      size={tinyScreen ? "tiny" : "small"}
                      variant="outlined"
                      whiteBackground
                      label={formatMessage({
                        defaultMessage: "Details",
                        description: "Goal card > details",
                      })}
                      linkTo={
                        detailButtonConfig.type === "link"
                          ? detailButtonConfig.path
                          : undefined
                      }
                      onClick={() => {
                        track(`Goal card details button clicked`, {
                          detailsType: detailButtonConfig.type,
                          ...analyticsData,
                        });

                        if (detailButtonConfig.type === "modal") {
                          setDetailsModalOpen(true);
                        }
                      }}
                    />
                    {detailButtonConfig.type === "modal" && (
                      <GoalDetailsModal
                        open={detailsModalOpen}
                        onClose={() => setDetailsModalOpen(false)}
                        goalIcon={goalIcon}
                        title={title}
                        status={status}
                        rewardConfig={rewardConfig}
                        contentsText={detailButtonConfig.contentsText}
                      />
                    )}
                  </>
                )}
                {primaryButtonProps && (
                  <Button
                    width="full"
                    size={tinyScreen ? "tiny" : "small"}
                    color="primary"
                    label={primaryButtonProps.label}
                    linkTo={primaryButtonProps.linkTo}
                    externalLink={primaryButtonProps.externalLink ?? false}
                    onClick={(e) => {
                      track(`Goal card primary button clicked`, {
                        label: primaryButtonProps.label,
                        ...analyticsData,
                      });
                      primaryButtonProps.onClick?.(e);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
