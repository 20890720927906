import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
export type GoalCardFragmentFragment = (
  { __typename?: 'Goal' }
  & Pick<Types.Goal, 'id' | 'type' | 'status' | 'numPoints' | 'numCaribouSponsoredDrawTickets' | 'numOrganizationDrawTickets' | 'progress' | 'trackName' | 'expiresAt'>
  & { goalTracker: (
    { __typename: 'JobShareGoalTracker' }
    & Pick<Types.JobShareGoalTracker, 'id'>
  ) | (
    { __typename: 'SurveyGoalTracker' }
    & Pick<Types.SurveyGoalTracker, 'id' | 'surveyType'>
  ) | (
    { __typename: 'EVVStreakGoalTracker' }
    & Pick<Types.EvvStreakGoalTracker, 'id' | 'targetStreak' | 'currentStreak'>
    & { trackedVisits: Array<(
      { __typename?: 'EVVStreakGoalTrackerVisitDetail' }
      & Pick<Types.EvvStreakGoalTrackerVisitDetail, 'id' | 'rangeStart' | 'rangeEnd' | 'status'>
    )> }
  ) | (
    { __typename: 'WatchVideoGoalTracker' }
    & Pick<Types.WatchVideoGoalTracker, 'id' | 'videoType'>
  ) | (
    { __typename: 'HHAXLoginGoalTracker' }
    & Pick<Types.HhaxLoginGoalTracker, 'id'>
  ), goalDetails?: Types.Maybe<(
    { __typename?: 'SurveyGoalDetails' }
    & Pick<Types.SurveyGoalDetails, 'externalSurveyUrl'>
  ) | (
    { __typename?: 'WatchVideoGoalDetails' }
    & Pick<Types.WatchVideoGoalDetails, 'videoId'>
  )>, unlockCriteria?: Types.Maybe<(
    { __typename: 'DependingGoalUnlockCriteria' }
    & { dependingGoal: (
      { __typename?: 'Goal' }
      & Pick<Types.Goal, 'id' | 'type'>
      & { goalTracker: (
        { __typename: 'JobShareGoalTracker' }
        & Pick<Types.JobShareGoalTracker, 'id'>
      ) | (
        { __typename: 'SurveyGoalTracker' }
        & Pick<Types.SurveyGoalTracker, 'id' | 'surveyType'>
      ) | (
        { __typename: 'EVVStreakGoalTracker' }
        & Pick<Types.EvvStreakGoalTracker, 'id' | 'targetStreak'>
        & { trackedVisits: Array<(
          { __typename?: 'EVVStreakGoalTrackerVisitDetail' }
          & Pick<Types.EvvStreakGoalTrackerVisitDetail, 'id' | 'rangeStart' | 'rangeEnd' | 'status'>
        )> }
      ) | (
        { __typename: 'WatchVideoGoalTracker' }
        & Pick<Types.WatchVideoGoalTracker, 'id' | 'videoType'>
      ) | (
        { __typename: 'HHAXLoginGoalTracker' }
        & Pick<Types.HhaxLoginGoalTracker, 'id'>
      ) }
    ) }
  ) | (
    { __typename: 'TimeBasedlUnlockCriteria' }
    & Pick<Types.TimeBasedlUnlockCriteria, 'unlocksAt'>
  )> }
);

export const GoalCardFragmentFragmentDoc = gql`
    fragment GoalCardFragment on Goal {
  id
  type
  status
  numPoints
  numCaribouSponsoredDrawTickets
  numOrganizationDrawTickets
  progress
  goalTracker {
    ... on JobShareGoalTracker {
      id
    }
    ... on SurveyGoalTracker {
      id
      surveyType
    }
    ... on HHAXLoginGoalTracker {
      id
    }
    ... on EVVStreakGoalTracker {
      id
      targetStreak
      currentStreak
      trackedVisits {
        id
        rangeStart
        rangeEnd
        status
      }
    }
    ... on WatchVideoGoalTracker {
      id
      videoType
    }
    ... on HHAXLoginGoalTracker {
      id
    }
    __typename
  }
  goalDetails {
    ... on SurveyGoalDetails {
      externalSurveyUrl
    }
    ... on WatchVideoGoalDetails {
      videoId
    }
  }
  unlockCriteria {
    ... on DependingGoalUnlockCriteria {
      dependingGoal {
        id
        type
        goalTracker {
          ... on JobShareGoalTracker {
            id
          }
          ... on SurveyGoalTracker {
            id
            surveyType
          }
          ... on HHAXLoginGoalTracker {
            id
          }
          ... on EVVStreakGoalTracker {
            id
            targetStreak
            trackedVisits {
              id
              rangeStart
              rangeEnd
              status
            }
          }
          ... on WatchVideoGoalTracker {
            id
            videoType
          }
          __typename
        }
      }
    }
    ... on TimeBasedlUnlockCriteria {
      unlocksAt
    }
    __typename
  }
  trackName
  expiresAt
}
    `;