/** @jsxImportSource @emotion/react */
import { ApolloError } from "@apollo/client";
import { css } from "@emotion/react";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@rewards-web/shared/components/button";
import { Form } from "@rewards-web/shared/components/form";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { isEmail } from "@rewards-web/shared/lib/is-email";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useUpdateEmailMutation } from "./update-email.generated";

export interface GiftCardRedeemDrawerConfirmNewEmailProps {
  userCurrentEmail: string;
  onCancel: (() => void) | null;
  onConfirmNewEmail: (newEmail: string) => void;
}

const HELP_EMAIL = "help@caribou.care";

export function GiftCardRedeemDrawerConfirmNewEmail({
  userCurrentEmail,
  onCancel,
  onConfirmNewEmail,
}: GiftCardRedeemDrawerConfirmNewEmailProps) {
  const { formatMessage } = useFormatters();

  const track = useTrack();

  const [updateEmail] = useUpdateEmailMutation({
    onCompleted: (data) => {
      const newEmail = data.updateMyRewardsUserEmail.email!;
      onConfirmNewEmail(newEmail);
      track("Update email successful", { newEmail });
    },
    onError: (error) => {
      const errorMessage = (() => {
        if (error instanceof ApolloError) {
          const firstError = error.graphQLErrors.at(0);
          const updateEmailErrorCode =
            firstError?.extensions?.update_email_error_code;
          if (updateEmailErrorCode === "EXISTING_USER_WITH_INFO") {
            return formatMessage({
              description:
                "Redeem points drawer > confirm new email > email conflict error",
              defaultMessage: "That email is already in use.",
            });
          }
        }

        // report error only if it's unexpected
        reportError(error);
        return formatMessage({
          description:
            "Redeem points drawer > confirm new email > unexpected error",
          defaultMessage: "Update failed. Try again.",
        });
      })();
      track("Update email failed", { errorMessage });
      setError("email", { message: errorMessage });
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitted, isSubmitSuccessful },
    control: formControl,
    getValues: getFormValues,
    setError,
  } = useForm<{ email: string; confirmEmail: string }>();

  const getEmailFieldErrors = (email: string) => {
    if (userCurrentEmail === email) {
      return formatMessage({
        description:
          "Redeem points drawer > confirm new email > same email error",
        defaultMessage: "New email must be different from current email",
      });
    }
    if (!isEmail(email)) {
      return formatMessage({
        description:
          "Redeem points drawer > confirm new email > invalid email error",
        defaultMessage: "Invalid email",
      });
    }
  };

  const getEmailConfirmFieldErrors = (confirmEmail: string) => {
    const { email } = getFormValues();
    // case insensitive -- in the backend, we lowercase emails anyway
    if (email.toLowerCase() !== confirmEmail.toLowerCase()) {
      return formatMessage({
        description:
          "Redeem points drawer > confirm new email > email confirmation field mismatch",
        defaultMessage: "Emails don't match",
      });
    }
  };

  const emailFieldTitle = formatMessage({
    description:
      "Redeem points drawer > confirm new email > email field placeholder",
    defaultMessage: "Enter new email",
  });

  const emailConfirmationFieldTitle = formatMessage({
    description:
      "Redeem points drawer > confirm new email > email confirmation field placeholder",
    defaultMessage: "Confirm new email",
  });

  return (
    <div
      css={(theme: AppTheme) => css`
        margin-top: ${theme.spacing(-0.75)};
      `}
    >
      <Form
        onSubmit={handleSubmit(
          ({ email: newEmail }) =>
            updateEmail({
              variables: {
                newEmail,
              },
            }),
          () => track("Update email pressed but input had errors", { errors })
        )}
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <Controller
          control={formControl}
          rules={{
            required: formatMessage({
              description:
                "Redeem points drawer > confirm new email > email required",
              defaultMessage: "Please enter your email",
            }),
            validate: (value: string) => getEmailFieldErrors(value),
            deps: ["confirmEmail"],
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={emailFieldTitle}
              placeholder={emailFieldTitle}
              error={fieldState.error}
            />
          )}
          name="email"
        />
        <Controller
          control={formControl}
          rules={{
            required: formatMessage({
              description:
                "Redeem points drawer > confirm new email > confirm email required",
              defaultMessage: "Please confirm your email",
            }),
            validate: (value: string) => getEmailConfirmFieldErrors(value),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={emailConfirmationFieldTitle}
              placeholder={emailConfirmationFieldTitle}
              error={fieldState.error}
            />
          )}
          name="confirmEmail"
        />
        <Typography
          textAlign="center"
          css={(theme: AppTheme) =>
            css`
              padding: ${theme.spacing(0, 2)};
              margin-bottom: ${theme.spacing(2.5)};
            `
          }
        >
          <FormattedMessage
            description="Redeem points drawer > confirm new email > description"
            defaultMessage="We will use this email for future communications."
          />
        </Typography>
        <div
          css={(theme: AppTheme) =>
            css`
              margin-top: ${theme.spacing(2)};
              display: flex;
              flex-direction: row;
              gap: ${theme.spacing(1.5)};
              > * {
                min-width: 170px;
                flex: 1;
              }
              flex-wrap: wrap;
            `
          }
        >
          {onCancel && (
            <Button
              type="button"
              onClick={() => {
                track(
                  "Cancel pressed in giftcard redemption update email flow - email form step"
                );
                onCancel();
              }}
              label={formatMessage({
                description:
                  "Redeem points drawer > confirm new email > cancel button label",
                defaultMessage: "Cancel",
              })}
              variant="outlined"
              size="large"
            />
          )}
          <Button
            type="submit"
            label={formatMessage({
              description:
                "Redeem points drawer > confirm new email > update email button label",
              defaultMessage: "Update email",
            })}
            color="primary"
            size="large"
            loading={isSubmitting}
          />
        </div>
        {isSubmitted && !isSubmitSuccessful && (
          <Typography
            variant="caption"
            css={(theme: AppTheme) =>
              css`
                margin-top: ${theme.spacing(2.5)};
              `
            }
          >
            <FormattedMessage
              description="Redeem points drawer > confirm new email > help footer"
              defaultMessage="Having trouble? Reach out to <email></email> for support."
              values={{
                br: () => <br />,
                email: () => (
                  <a href={`mailto:${encodeURI(HELP_EMAIL)}`}>{HELP_EMAIL}</a>
                ),
              }}
            />
          </Typography>
        )}
      </Form>
    </div>
  );
}
