/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

import { Button } from "@rewards-web/shared/components/button";
import { Confetti } from "@rewards-web/shared/components/confetti";
import { ResponsiveDialog } from "@rewards-web/shared/components/responsive-dialog";
import { Typography } from "@rewards-web/shared/components/typography";
import { RedemptionMethod } from "@rewards-web/shared/graphql-types";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { useFormatters } from "@rewards-web/shared/modules/formatter";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { useAppTheme } from "@rewards-web/shared/style/theme";
import { AppTheme } from "@rewards-web/shared/style/types";

import { usePointBalance } from "../../../../../../shared/modules/point-balance";
import { PointsIcon } from "../../../../../../shared/points-icon";
import { useRedeemPointsMutation } from "../redeem-points.generated";

export interface PaycheckRedeemPointsDrawerProps {
  open: boolean;
  onClose: () => void;
  onRedeemed: () => void;
  pointsAvailableToRedeem: number;
  pointsPerDollar: number;
}

export function PaycheckRedeemPointsDrawer({
  open,
  onClose,
  onRedeemed,
  pointsAvailableToRedeem,
  pointsPerDollar,
}: PaycheckRedeemPointsDrawerProps) {
  const { formatMessage } = useFormatters();
  const theme = useAppTheme();

  const pointBalance = usePointBalance({ refreshOnMount: true });
  const snackbar = useSnackbar();
  const [isShowingConfetti] = useState(false);

  const [
    redeemPoints,
    { loading: redeemPointsLoading },
  ] = useRedeemPointsMutation({
    onError: (error) => {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: formatMessage({
          description: "Redeem points drawer > error",
          defaultMessage:
            "Something went wrong when redeeming your points. Please try again later.",
        }),
      });
    },
  });

  const onRedeem = async () => {
    await redeemPoints({
      variables: {
        amount: pointBalance.computedBalance!,
        redemptionMethod: RedemptionMethod.Payroll,
      },
    });
    onRedeemed();
    onClose();
  };

  return (
    <>
      <Confetti active={isShowingConfetti} />
      <ResponsiveDialog
        title={formatMessage({
          defaultMessage: "Redeem points",
          description: "Redeem points drawer > title",
        })}
        open={open}
        onClose={onClose}
        maxWidth="430px"
        paddingBottom={0}
        backgroundColor={theme.palette.background.paper}
      >
        <div
          css={(appTheme: AppTheme) =>
            css`
              padding: ${appTheme.spacing(2.5)};
              padding-top: 0;
            `
          }
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            `}
          >
            <div
              css={(appTheme: AppTheme) => css`
                text-transform: uppercase;
                padding: ${appTheme.spacing(0.5)} ${appTheme.spacing(1.5)};
                border-radius: 500px;
                background-color: ${appTheme.palette.tertiary.main};
                display: flex;
                align-items: center;
                gap: ${appTheme.spacing(1.25)};
              `}
            >
              <PointsIcon />
              <Typography variant="caption" color="white">
                {formatMessage(
                  {
                    defaultMessage: "{redeemable_points, number} points",
                    description: "Redeem points drawer > number of points",
                  },
                  { redeemable_points: pointsAvailableToRedeem }
                )}
              </Typography>
            </div>
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin: ${appTheme.spacing(2.5)} 0;
                `
              }
              variant="h1"
            >
              {formatDollars(pointsAvailableToRedeem / pointsPerDollar)}
            </Typography>
            <Typography
              css={(appTheme: AppTheme) =>
                css`
                  margin-bottom: ${appTheme.spacing(1.5)};
                `
              }
              variant="h5"
            >
              {formatMessage({
                defaultMessage: "Redemptions will be added to your paycheck.",
                description: "Redeem points drawer > paycheck note",
              })}
            </Typography>
            <Typography variant="body">
              {formatMessage({
                defaultMessage:
                  "It can take up to 3 weeks for it to appear. Applicable taxes will apply.",
                description:
                  "Redeem points drawer > paycheck redemptions weeks note",
              })}
            </Typography>
          </div>
          <div
            css={(appTheme: AppTheme) => css`
              display: flex;
              align-items: center;
              margin-top: ${appTheme.spacing(3.75)};
            `}
          >
            <Button
              label={formatMessage({
                defaultMessage: "Cancel",
                description: "Redeem points drawer > cancel button",
              })}
              onClick={() => onClose()}
              variant="outlined"
            />
            <Button
              loading={redeemPointsLoading}
              onClick={onRedeem}
              color="secondary"
              css={(appTheme: AppTheme) =>
                css`
                  margin-left: ${appTheme.spacing(1)};
                `
              }
              label={formatMessage({
                defaultMessage: "Redeem",
                description: "Redeem points drawer > redeem button",
              })}
            />
          </div>
        </div>
      </ResponsiveDialog>
    </>
  );
}
