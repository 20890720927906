/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { orderBy } from "lodash";

import { Alert } from "@rewards-web/shared/components/alert";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Typography } from "@rewards-web/shared/components/typography";
import { DrawWinnerClaimedPrize } from "@rewards-web/shared/graphql-types";
import { reportError } from "@rewards-web/shared/modules/error";
import {
  FormattedMessage,
  useFormatters,
} from "@rewards-web/shared/modules/formatter";
import { AppTheme } from "@rewards-web/shared/style/types";

import { PageContentContainer } from "../../../../../shared/page-content-container";
import { PastDraw, PastDrawCard } from "./past-draw-card";
import pastDrawsGraphic from "./past-draws-graphic.png";
import { usePastDrawsPageDataQuery } from "./past-draws-page-data.generated";

export function PastDrawsPage() {
  const { formatMessage } = useFormatters();
  const pageDataQuery = usePastDrawsPageDataQuery({
    onError: reportError,
  });

  const content = (() => {
    if (pageDataQuery.error) {
      return (
        <Alert
          severity="error"
          message={formatMessage({
            description: "Past draws page > error loading data",
            defaultMessage:
              "An unexpected error occurred. Please try again later.",
          })}
        />
      );
    }

    if (!pageDataQuery.data) {
      return (
        <>
          <Skeleton height={40} width="50%" />
          <Skeleton animated height={110} />
          <Skeleton animated height={110} />
          <Skeleton animated height={110} />
        </>
      );
    }

    if (pageDataQuery.data.pastDraws.length === 0) {
      return (
        <div
          css={(theme: AppTheme) => css`
            border: 2px dashed ${theme.palette.grey[200]};
            padding: ${theme.spacing(7)} ${theme.spacing(3)};
          `}
        >
          <img
            src={pastDrawsGraphic}
            alt="Gift and ticket"
            css={(theme: AppTheme) => css`
              display: block;
              width: 92px;
              margin: 0 auto;
              margin-bottom: ${theme.spacing(3)};
            `}
          />
          <Typography variant="h4" color="textPrimary" textAlign="center">
            <FormattedMessage
              defaultMessage="New draws coming soon!"
              description="Past draws page > empty state title"
            />
          </Typography>
        </div>
      );
    }

    const [unclaimedDraws, otherDraws] = orderBy(
      pageDataQuery.data.pastDraws,
      (draw) => draw.scheduledStartDate,
      "desc"
    ).reduce(
      (acc, item) => {
        const isWinnerAndPrizeNotClaimed = item.winners?.some(
          (winner) =>
            winner.isMe && winner.claimedPrize === DrawWinnerClaimedPrize.No
        );

        if (isWinnerAndPrizeNotClaimed) {
          acc[0].push(item);
        } else {
          acc[1].push(item);
        }

        return acc;
      },
      [[], []] as [PastDraw[], PastDraw[]]
    );

    return (
      <>
        <Typography
          variant="h4"
          component="h2"
          css={(theme: AppTheme) => css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <FormattedMessage
            defaultMessage="Past draws"
            description="Past draws page > title"
          />
        </Typography>

        {unclaimedDraws.map((draw) => (
          <PastDrawCard key={draw.id} draw={draw} hasUnclaimedPrize={true} />
        ))}

        {otherDraws.map((draw) => (
          <PastDrawCard key={draw.id} draw={draw} hasUnclaimedPrize={false} />
        ))}
      </>
    );
  })();

  return (
    <div
      css={(theme: AppTheme) => css`
        background-color: #ffffff;
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <PageContentContainer
        css={(theme: AppTheme) => css`
          padding-top: ${theme.spacing(3)};
        `}
      >
        {content}
      </PageContentContainer>
    </div>
  );
}
