import { pipe } from "lodash/fp";

import { SchedulingSoftware } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { useGoalRewardsUserDataQuery } from "../goal-rewards-user.query.generated";
import { O } from "./fp";

const SCHEDULING_SOFTWARE_DEFAULT_APP_NAME = "scheduling software";

export type SchedulingSoftwareDetails = {
  appName: string;
  appStoreLink: string;
  playStoreLink: string;
};

export const getAppNameOrDefault = O.match(
  () => SCHEDULING_SOFTWARE_DEFAULT_APP_NAME,
  (details: SchedulingSoftwareDetails) => details.appName
);

export const getSchedulingSoftwareDetails = (
  userDataQueryResult: ReturnType<typeof useGoalRewardsUserDataQuery>
): O.Option<SchedulingSoftwareDetails> =>
  pipe(
    O.fromNullable,
    O.flatMap(getDetailsFromSchedulingSoftware)
  )(userDataQueryResult.data?.getMyRewardsUser.schedulingSoftware);

const getDetailsFromSchedulingSoftware = (
  schedulingSoftware: SchedulingSoftware
): O.Option<SchedulingSoftwareDetails> => {
  switch (schedulingSoftware) {
    case SchedulingSoftware.Hhaexchange:
      return O.some({
        appName: "HHAeXchange",
        appStoreLink: "https://apps.apple.com/us/app/hhaexchange/id883673336",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.hhaexchange.caregiver",
      });
    case SchedulingSoftware.Alayacare:
      return O.some({
        appName: "AlayaCare",
        appStoreLink: "https://apps.apple.com/us/app/alayacare/id1030754584",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.alayacare.careworkerapp",
      });
    case SchedulingSoftware.Axiscare:
      return O.some({
        appName: "AxisCare",
        appStoreLink:
          "https://apps.apple.com/us/app/axiscare-mobile/id1081635097",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.axiscare",
      });
    case SchedulingSoftware.Wellsky:
      return O.some({
        appName: "WellSky",
        appStoreLink:
          "https://apps.apple.com/us/app/wellsky-personal-care/id1304726452",
        playStoreLink:
          "https://play.google.com/store/apps/details?id=com.clearcare.clearcareconnect",
      });
    case SchedulingSoftware.Unknown:
      return O.none;

    default:
      assertNever(
        schedulingSoftware,
        `Unrecognized scheduling software ${schedulingSoftware}`
      );
  }
};
