export type None = {
  _tag: "None";
};

export type Some<T> = {
  _tag: "Some";
  value: T;
};

export type Option<T> = None | Some<T>;

export const some = <T>(x: T): Some<T> => ({
  _tag: "Some",
  value: x,
});

export const none = {
  _tag: "None",
} as const;

export const isSome = <T>(x: Option<T>): x is Some<T> => x._tag === "Some";

export const isNone = <T>(x: Option<T>): x is None => x._tag === "None";

export const map = <A, B>(f: (a: A) => B) => (fa: Option<A>): Option<B> =>
  isSome(fa) ? some(f(fa.value)) : none;

export const flatMap = <A, B>(f: (a: A) => Option<B>) => (
  fa: Option<A>
): Option<B> => (isSome(fa) ? f(fa.value) : none);

export const fromNullable = <T>(x: T | null | undefined): Option<T> =>
  x === null || x === undefined ? none : some(x);

export const match = <A, B>(onNone: () => B, onSome: (a: A) => B) => (
  fa: Option<A>
): B => (isSome(fa) ? onSome(fa.value) : onNone());
